<template>
<div>
    <div v-if="finish === false" >
        <div class="text-center" style="text-align: center;margin-top:100px">
                <v-progress-circular v-if="finish === false"
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate>
                </v-progress-circular>  
        </div>
    </div>
    <div v-if="finish">
        <v-toolbar
                dense
                color="#085587"
                dark
                extended
                extension-height="220"
                flat
        >
            <div style="width:-webkit-fill-available;text-align:right;">
                <v-menu> 
                    <v-btn icon slot="activator">
                        <v-avatar class="white" size="32">
                            <v-icon color="#085587">person</v-icon>
                        </v-avatar>
                    </v-btn>
                    <v-list class="pa-0" light>
                    

                        <v-list-tile  @click="dialog_cad = true">
                            <v-list-tile-action>
                                <v-icon>vpn_key</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>Alterar Senha</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-divider></v-divider>



                        <v-list-tile @click="logout" >
                            <v-list-tile-action>
                                <v-icon>lock_open</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>Sair</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-menu>

            </div>
            <v-toolbar-title slot="extension" style="margin-left: 8px !important">
                <span>Seja Bem Vindo!</span>
                <h3>{{this.cliente.NomeCompleto}}</h3> 
                <h6>CEP: {{this.cliente.EnderecoCep}}</h6> 
                <h6>{{this.cliente.EnderecoLogradouro}}, Nº{{this.cliente.EnderecoLogradouroNumero}}</h6> 
                <h6>Contato: {{this.cliente.PrefixoTelefone}}{{this.cliente.Telefone}} - {{this.cliente.PrefixoCelular}}{{this.cliente.Celular}} <br/> Email: {{this.cliente.Email}}</h6> 

                <v-layout row  style="width: fit-content">

                    <v-flex wrap>
                        <v-btn outline fab color="white"  @click="dlg_lista = true" class="outine-2" small>
                              <i class="fa fa-tasks fa-2x" ></i>
                        </v-btn>
                    </v-flex>

                    <v-flex wrap>
                        <v-btn outline  fab color="white"  @click="dlg_end = true" class="outine-2" small>
                              <i class="fa fa-users fa-2x"></i>
                        </v-btn>
                    </v-flex>

                    <v-flex wrap>
                        <v-btn outline  fab color="white"  @click="dlg_end = true" class="outine-2" small>
                              <i class="fa fa-id-card fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    
                   <v-flex wrap>
                        <v-btn outline  fab color="white" to="/contato" class="outine-2" small>
                              <i class="fa fa-at fa-2x"></i>
                        </v-btn>
                    </v-flex>

                    <v-flex wrap>
                        <v-btn outline  fab color="white" to="/sinistro" class="outine-2" small>
                              <i class="fa fa-cross fa-2x"></i>
                        </v-btn>
                    </v-flex>

                    
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container >
          
            <v-layout row  >
                <v-flex>
                    <v-card flat  color="transparent">
                        <v-container fluid grid-list-lg >
                            <v-layout row wrap>
                                <v-flex md12 lg3 v-for="item in links" :key="item.id">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`">
                                        
                                            <v-card flat style="color:white;text-align: center;" class="text-xs-center"
                                                    height="110px"
                                                    :class="item.color">  
                                                <br/>

                                                <i v-if="item.id === '1'" class="fa fa-tasks fa-3x"></i>
                                                <i v-if="item.id === '2'" class="fa fa-users fa-3x"></i>
                                                <i v-if="item.id === '3'" class="fa fa-id-card fa-3x"></i>
                                                <i v-if="item.id === '4'" class="fa fa-at fa-3x"></i>
                                                <i v-if="item.id === '5'" class="fa fa-cross fa-3x"></i>
                                                <i v-if="item.id === '6'" class="fa fa-comments fa-3x"></i>

                                            </v-card>
                                            
                                            <v-card-title primary-title>
                                                <div>
                                                    <h3 class="headline mb-0">{{item.title}}</h3>
                                                    <div style="height:80px">
                                                        {{item.description}}
                                                    </div>
                                                </div>
                                            </v-card-title>

                                            <v-card-actions>
                                               
                                                <v-spacer></v-spacer>
                                                <v-btn v-if="item.id === '1'" @click="dlg_lista = true" flat color="primary">Entrar</v-btn>
                                                <v-btn v-if="item.id === '2'" @click="dlg_lista_dep = true" flat color="primary">Entrar</v-btn>
                                                <v-btn v-if="item.id === '3'" @click="dlg_end = true" flat color="primary">Entrar</v-btn>
                                                <v-btn v-if="item.id === '4'" to="/contato" flat color="primary">Entrar</v-btn>
                                                <v-btn v-if="item.id === '5'" to="/sinistro" flat color="primary">Entrar</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

   

      <v-dialog  v-model="dlg_retorno_digital" max-width="300px">
            <v-card>
                <v-toolbar color="primary" dark> <span class="headline">Mensagem</span></v-toolbar>
                <v-card-text>
                    Solicitação enviada com sucesso! Qualquer dúvida entrar em contato conosco.
                    <br/>
                    Telefone: 0800 009 1700
                    <br/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text  @click="dlg_retorno_digital = false"> OK </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog  v-model="dlg_lista" max-width="500px">
            <v-card>
                <v-toolbar color="primary" dark> <span class="headline">Confirmar Contrato</span></v-toolbar>
                <v-card-text>
                    <br/>
                    <v-select  v-model="escolhido"  :items="this.lista_contratos_" label="Selecione seu contrato" item-text="Referencia" box></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text flat @click="dlg_lista = false" > Sair </v-btn>
                    <v-btn color="primary" text  @click="acessar_mensalidade"> Confirmar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

         <v-dialog  v-model="dlg_lista_dep" max-width="500px">
            <v-card>
                <v-toolbar color="primary" dark> <span class="headline">Confirmar Contrato</span></v-toolbar>
                <v-card-text>
                    <br/>
                    <v-select  v-model="escolhido"  :items="this.lista_contratos_" label="Selecione seu contrato" item-text="Referencia" box></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text flat @click="dlg_lista_dep = false" > Sair </v-btn>
                    <v-btn color="primary" text  @click="acessar_dep"> Confirmar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog  v-model="dlg_end" max-width="500px">
            <v-card>
                <v-toolbar color="primary" dark> <span class="headline">Endereço</span></v-toolbar>
                 <v-card-text>
                 <v-layout wrap>
                    <v-flex xs12>
                        <v-input 
> 
                            ENDEREÇO ATUAL <br/>
                            CEP: {{this.cliente.EnderecoCep}} <br/>
                            {{this.cliente.EnderecoLogradouro}}, Nº{{this.cliente.EnderecoLogradouroNumero}}<br/>
                            Complemento: {{this.cliente.EnderecoComplemento}} - Bairro: {{this.cliente.EnderecoBairro.Nome}}<br/>
                            Cidade: {{this.cliente.EnderecoCidade.Nome}} - UF: {{this.cliente.EnderecoUF.Nome}}
                        </v-input>
                    </v-flex>
                 </v-layout>
                 </v-card-text>

                
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text flat @click="dlg_end = false" > Sair </v-btn>
                    <v-btn color="primary" text  @click="acessar_endereco"> Alterar Endereço </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



    </div>

    <v-dialog
      v-model="dialog_cad"
      persistent
      max-width="500px"
    >
       <v-form
        ref="form"
        v-model="valid"
        lazy-validation>

      <v-card>
        <v-card-title>
          <span class="headline">Alterar Senha</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-flex xs12>
                  <v-text-field color="success"
                    label="EMAIL" Required :rules="[v => !!v || 'Campo Obrigatório']"
                    flat v-model="cadastro.email"
                    prepend-inner-icon="alternate_email"
                  ></v-text-field>
                </v-flex>

              <v-flex xs12>
                  <v-text-field color="success" Required :rules="[v => !!v || 'Campo Obrigatório']"
                    label="SENHA" type="password" v-model="cadastro.senha"
                    prepend-inner-icon="vpn_key"
                    flat 
                  ></v-text-field>
                </v-flex>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            flat
            :disabled="btn_cad_load"
            @click="dialog_cad = false"
          >
            Sair
          </v-btn>
          <v-btn
            color="success"
            :loading="btn_cad_load"
            text
            @click="handleAlt"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
       </v-form>
    </v-dialog>

     <v-dialog v-model="finish_alterar_senha" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Mensagem</v-card-title>
                <v-card-text>{{msg_finish_alterar}}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat @click="finish_alterar_senha = false" >OK</v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>

    <v-dialog
      v-model="dlg_roleta" 
      persistent 
      width="620px" >
     
      <v-card style="background-color:rgb(0 0 0 / 0%)"> 
            <div align="center">
                <table cellpadding="0" cellspacing="0" border="0">
                    <tr>
                        <br/>
                        <br/>
                        <td width="580" height="580" class="the_wheel" align="center" valign="center">
                            <br/>
                            <br/>
                            <br/>
                            <canvas id="canvas" width="540" height="540">
                            
                            </canvas>  
                             <v-btn rounded color="green" dark @click="startSpin()"> GIRAR ROLETA </v-btn>        
                            <br />  
                        </td>
                    </tr>
                </table>
            </div> 
      </v-card>
    </v-dialog>

    

    </div>
</template>

<script>
import { http } from '../../services/config'
import { httpOpen } from '../../services/configOpen'
import axios from 'axios'
import authHeader from '../../services/auth-header';
import { mapGetters, mapActions } from 'vuex'

    export default {
       
        computed:{
            ...mapGetters([
                    'lista_contratos' , 'cliente_select', 
                ]),


        },

        data() {
            return {
                cliente: {},
                vl: false, 
                theWheel: null,
                wheelPower: 0,
                wheelSpinning: false,
                dlg_retorno_digital: false,
                finish_alterar_senha:false,
                msg_finish_alterar: '',
                dialog_cad: false,
                dlg_roleta: false,
                btn_cad_load: false,
                finish: false,
                btn_resposta_load: false,
                cadastro: {
                    email: '',
                    senha: '',
                },
                

                lista_contratos_: [],
                conti: [],
                dlg_lista: false,
                dlg_lista_dep: false,
                dlg_end: false,
                cliente: {},
                escolhido:{},
                links: [
                    {
                        id: '1',
                        title: 'Mensalidade',
                        description: 'Pagamento de mensalidade no cartão de crédito, imprimir segunda via de boletos e código de barras.',
                        color: 'orange',
                        url: '/mensalidade',
                    },
                    {
                        id: '2',
                        title: 'Dependente',
                        description: 'Mantenha o cadastro dos dependentes atualizado',
                        color: 'red',
                        url: '/Dependente',
                    },
                    
                    {
                        id: '3',
                        title: 'Endereço',
                        description: 'Mantenha atualizado suas informações (Endereço de Cadastro e Cobrança)',
                        color: 'teal',
                        url: '/mensalidade',
                    },
                    {
                        id: '4',
                        title: 'Contato',
                        description: 'Mantenha atualizado suas informações de contatos (Telefones e E-mail)',
                        color: 'blue',
                        url: '/mensalidade',
                    },
                    {
                        id: '5',
                        title: 'Óbito',
                        description: 'Informações para abertura de Sinistro.',
                        color: 'purple',
                        url: '/mensalidade',
                    },
                   
                ]
            }
        },
      
        mounted() {
            const axiosPromises = []; 

            if(this.$store.getters.lista_contratos === undefined | this.isEmptyObject(this.$store.getters.lista_contratos)){
                http.get('ContratoDIO/Listar/', {
                    headers: authHeader()
                }).then(response =>{
                        var lista_contratos_aux = response.data.ResponseData;
                        this.lista_contratos_ = [];   
                        this.executarConsulta(lista_contratos_aux);
   
                    })
                    .catch(function (error) {
                    }).finally(() => {
  
                 });
           
             }else{

                        this.lista_contratos_ = this.$store.getters.lista_contratos;
                        for (var key in  this.lista_contratos_) {
                            this.id_cliente = this.lista_contratos_[key].Cliente.Id
                            sessionStorage.setItem('contrato_token', this.lista_contratos_[key].Referencia);

                            } 
                        this.request_obter_cliente(this.id_cliente)
            }

            
        },

        methods:{
                logout(){
                      sessionStorage.removeItem('user')
                      this.$router.push('/')
                },

                acessar_mensalidade(){
                     //localStorage.setItem('ls_contratos', self.contratos)
                     for (var key in  this.lista_contratos_) {
                         if(this.lista_contratos_[key].Referencia === this.escolhido){
                            sessionStorage.setItem('id_c_s', JSON.stringify(this.lista_contratos_[key]))
                            this.dlg_lista = false;
                            this.$router.push('/mensalidade')
                         }
                    } 
                    this.dlg_lista = false;
                },

               
                acessar_dep(){
                     for (var key in  this.lista_contratos_) {
                         if(this.lista_contratos_[key].Referencia === this.escolhido){
                            sessionStorage.setItem('id_c_s', JSON.stringify(this.lista_contratos_[key]))
                            this.dlg_lista_dep = false;
                            this.$router.push('/dependente')
                         }
                    } 
                    this.dlg_lista = false;
                },

      

                acessar_endereco(){
                    this.dlg_end = false;
                    this.$router.push('/endereco')
                },
            
             validCNPJ(ContratoAux, id_plano) { 
                                httpOpen.get('empresadoplano/'+ id_plano, {
                                    headers: authHeader()
                                })
                                .then(response => {
                                  
                                  if(response.data == 'VERSANN'){
                                        
                                        this.id_cliente = ContratoAux.Cliente.Id
                                        
                                        if(ContratoAux.RegistroAtivo){
                                            this.lista_contratos_.push(ContratoAux);
                                            sessionStorage.setItem('contrato_token', ContratoAux.Referencia);
                                           
                                        }
                                       

                                        this.$store.commit('setListaContratos', this.lista_contratos_)
                                        this.request_obter_cliente(this.id_cliente)
                                        return this.lista_contratos_;
                                    
                                  }

                    })
                },
                
                handleAlt() { 
                    if (this.$refs.form.validate()) {
                        this.btn_cad_load = true
                                http.get('AcessoCliente/AlterarDadosAcesso/', this.cadastro, {
                                    headers: authHeader()
                                })
                                .then(response => {
                                    this.msg_finish_alterar = response.data.Description
                                    this.finish_alterar_senha = true
                                    this.btn_cad_load = false
                                    this.dialog_cad = false
                                })                                 
                    }
                },


               request_obter_cliente(id) {
                http.get('ClienteDIO/Obter/'+ id, {
                    headers: authHeader()
                }).then(response => {
                        this.cliente = response.data.ResponseData;
                        this.$store.commit('setCliente', this.cliente)
                        this.finish = true
                    })
                    .catch(function (error) {

                    });
                },

            isEmptyObject(obj) {
                        var name;
                        for (name in obj) {
                            return false;
                        }
                        return true;
            },
        
            isEmpty(obj) {
                for(var prop in obj) {
                    if(obj.hasOwnProperty(prop))
                        return false;
                }

                return true;
            },

            async executarConsulta(listaItens) {
                // Seu loop (por exemplo, uma lista de itens)
                let pos = 0;
                for (var item of listaItens) {


                    // Realiza a consulta Axios aqui
                    // Por exemplo, vamos simular uma chamada assíncrona para axios usando setTimeout
                    
                    const response = await this.fazerConsulta(item, item.Tipo.Id);
                    pos++;
                    // Supondo que você tenha obtido um resultado "res" da consulta
                    //const res = item; // Aqui, estou apenas usando o próprio valor do item para simular a resposta                   

                    // Caso algum valor seja verdadeiro, atualize a variável vl e interrompa o loop
                    if (response.data === 1) {
                        this.vl = true;
                    }

                    
                }

                // Após o loop, verifique o valor de vl e mostre o alerta correspondente
                if (this.vl) {
                } else {
                    alert('Desculpa, Não foi encontrado nenhum contrato ativo para esse contrato.');
                    this.$router.push('/')
                }
            },

            async fazerConsulta(ContratoAux, item) {
                const id_plano = item; // Você precisa fornecer o ID apropriado do item para a consulta

                // Realize a consulta Axios aqui
                // Por exemplo, vamos simular uma chamada assíncrona para axios usando setTimeout
                return new Promise((resolve) => {
                    httpOpen.get('empresadoplano/'+ id_plano, {
                                    headers: authHeader()
                                })
                                .then(response => {
                                  
                              
                                  if(response.data == 'VERSAN'){
                                        this.id_cliente = ContratoAux.Cliente.Id
                                        if(ContratoAux.RegistroAtivo){
                                            this.lista_contratos_.push(ContratoAux);
                                            sessionStorage.setItem('contrato_token', ContratoAux.Referencia);
                                        }
                                        this.$store.commit('setListaContratos', this.lista_contratos_)
                                        this.request_obter_cliente(this.id_cliente)
                                    
                                        resolve({
                                            data: 1,
                                        });

                                    }else{

                                        resolve({
                                            data: 0,
                                        });
                                    }

                                })
                    }).catch(error => {
                        console.error('Erro na consulta:', error);
                        resolve({
                            data: 0,
                        });
                        })
                    }
            },
       
    }

</script>

<style>
  

   
    .v-dialog {
        box-shadow: none;
    }

    .outine-2 {
        border: 2px solid white;
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>